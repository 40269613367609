import styles from "./blog-card.module.scss"
import React from "react"
import MailSvg from "../svg/mail"
import TwitterSvg from "../svg/twitter"
import LinkedinSvg from "../svg/linkedin"
import ArrowSvg from "../svg/arrow"
import { Link } from "gatsby"
import { ENV_SITE_URL } from "../../utils/secrets"
import { Helper } from "../../utils/helper"

const BlogCard = ({
  title,
  date,
  author,
  author2,
  author3,
  excerpt,
  externalUrl,
  slug,
}) => {
  const posterTitle = `${title}`
  const articleUrl = !!externalUrl
    ? externalUrl
    : `${ENV_SITE_URL}/resources/blog/${slug}`

  const emailShareUrl = Helper.getEmailShareUrl(
    posterTitle,
    articleUrl,
    excerpt
  )
  const twitterShareUrl = Helper.getTwitterShareUrl(posterTitle, articleUrl)
  const linkedInShareUrl = Helper.getLinkedinShareUrl(posterTitle, articleUrl)

  const ShareComponent = !!slug ? Link : "a"
  const shareProps = !!slug
    ? {
        to: `/resources/blog/${slug}`,
      }
    : {
        target: "_blank",
        rel: "noopener noreferrer",
        href: externalUrl,
      }

  return (
    <div className={styles.outerWrapper}>
      <div className={styles.socialLinks}>
        <a
          ves-e2e-test="blog/article-email-share-link"
          aria-label="Share via e-mail"
          target="_blank"
          rel="noopener noreferrer"
          href={emailShareUrl}>
          <MailSvg />
        </a>
        <a
          ves-e2e-test="blog/article-twitter-share-link"
          aria-label="Share on Twitter"
          target="_blank"
          rel="noopener noreferrer"
          href={twitterShareUrl}>
          <TwitterSvg />
        </a>
        <a
          ves-e2e-test="blog/article-linkedin-share-link"
          aria-label="Share on Linkedin"
          target="_blank"
          rel="noopener noreferrer"
          href={linkedInShareUrl}>
          <LinkedinSvg />
        </a>
      </div>
      <ShareComponent
        aria-label="Read more"
        className={styles.outerLink}
        {...shareProps}>
        <div className={`row ${styles.wrapper}`}>
          <div className={`col-12 align-items-start ${styles.container}`}>
            <div className={`align-items-start`}>
              <div className={`col-12 ${styles.content}`}>
                <div className={`col-12 ${styles.content__header}`}>
                  <div
                    className={`d-flex ${styles.content__headerTitle}`}
                    ves-e2e-test="blog/article-header">
                    <p>{date}</p>{" "}
                    <p className={styles.content__headerTitleDivider}>|</p>{" "}
                    <p>
                      {author}
                      {author2 && (
                        <span>
                          {!author3 && <span> and </span>}
                          {author3 && <span>, </span>}
                        </span>
                      )}
                      {author2}
                      {author3 && <span> and </span>}
                      {author3}
                    </p>
                  </div>
                </div>

                <div className={`col-12 ${styles.content__main}`}>
                  <h3
                    ves-e2e-test="blog/article-title"
                    className={styles.content__mainTitle}>
                    {title}
                  </h3>
                  <p className={styles.content__mainDescription}>{excerpt}</p>
                </div>

                <div className={`col-12 ${styles.content__footer}`}>
                  <p className={styles.content__footerLink}>
                    Read more
                    <ArrowSvg />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ShareComponent>
    </div>
  )
}

export default BlogCard
