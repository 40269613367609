import styles from "../../styles/blog.module.scss"
import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import BlogCard from "../../components/blog-card/blog-card"
import withAppContext from "../../contexts/app.context"
import FeedSvg from "../../components/svg/feed"

const BlogsPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      blogs: allMarkdownRemark(
        filter: {
          fields: { category: { eq: "blogs" } }
          frontmatter: { draft: { in: [false, null] } }
        }
        sort: { order: DESC, fields: frontmatter___date }
      ) {
        edges {
          node {
            frontmatter {
              author3
              author2
              author
              title
              previewTitle
              excerpt
              draft
              externalUrl
              date(formatString: "MMM DD YYYY")
              slug
            }
          }
        }
      }
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "resources__blog" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
      },
    },
  } = queryResult
  const filteredBlogs = queryResult.blogs.edges

  return (
    <Layout>
      <div
        className={`container volterra-container resource-center ${styles.blog}`}>
        <SEO
          title={metaTitle}
          pageUrl={"/resources/blog"}
          description={metaDescription}
          keywords={metaKeywordList}
          imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        />

        <div className="container volterra-container">
          <div className={styles.blogHeader}>
            <h1 className={styles.blogTitle}>Blog</h1>
            <a className={styles.blogFeed} href="/resources/blog/feed.xml">
              <FeedSvg />
            </a>
          </div>
        </div>

        <div className="row justify-content-center align-items-stretch justify-content-md-start">
          {filteredBlogs.map(res => (
            <BlogCard
              key={res.node.frontmatter.title}
              title={
                res.node.frontmatter.previewTitle || res.node.frontmatter.title
              }
              date={res.node.frontmatter.date}
              author={res.node.frontmatter.author}
              author2={res.node.frontmatter.author2}
              author3={res.node.frontmatter.author3}
              excerpt={res.node.frontmatter.excerpt}
              externalUrl={res.node.frontmatter.externalUrl}
              slug={res.node.frontmatter.slug}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default withAppContext(BlogsPage)
