import React from "react"

const FeedSvg = () => (
  <svg
    width="69"
    height="69"
    viewBox="0 0 69 69"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M8.997 68.994a8.997 8.997 0 110-17.994 8.997 8.997 0 010 17.994zM0 24c24.795 0 44.977 20.176 44.977 44.976H32.983c0-18.187-14.797-32.984-32.983-32.984V24zM0 0c38.027 0 68.962 30.938 68.963 68.965H56.968C56.968 37.55 31.413 11.994 0 11.994V0z"></path>
  </svg>
)

export default FeedSvg
