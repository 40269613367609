import React from "react"

const MailSvg = () => (
  <svg
    width="16px"
    height="14px"
    viewBox="0 0 16 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="blog-new"
        transform="translate(-994.000000, -302.000000)"
        fill="#A0A7C2"
        fillRule="nonzero">
        <g id="Group-2" transform="translate(320.000000, 293.000000)">
          <path d="M690,13.7 L690,22 C690,22.6 689.6,23 689,23 L689,23 L675,23 C674.4,23 674,22.6 674,22 L674,22 L674,13.7 L681.5,17.9 C681.78,18.04 682.22,18.04 682.5,17.9 L682.5,17.9 L690,13.7 Z M689,9 C689.6,9 690,9.4 690,10 L690,10 L690,11.5 L682,15.9 L674,11.4 L674,10 C674,9.4 674.4,9 675,9 L675,9 Z"></path>
        </g>
      </g>
    </g>
  </svg>
)

export default MailSvg
